import {endpoints} from './endpoints'
import request from '../helpers/request'

export function createUserHolter(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.createHolter,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function startUserHolter(){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.startHolter,
      method: 'post'
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function stopUserHolter(){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.stopHolter,
      method: 'post'
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function getHolterData(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getHolterData,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function deleteHolter(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.deleteHolter,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}