<template>
  <div>
    <CModal
      title="Test Holter"
      color="primary"
      class="modal-alerta"
      :show.sync="showModal"
      :closeOnBackdrop="false"
    >
      <template #header>
        <p class="mb-0 text-center w-100">Mediciones Realizadas para Test Holter #: {{holterID}}</p>
        <CButton
          class="mx-2 p-0"
          @click="startCloseModal()"
        >
          <p class="text-white mb-0 font-weight-bold">X</p>
        </CButton>
      </template>
      <CRow>
        <CCol col="12" xl="12">
          <div 
            v-if="dataLoading"
            class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
            <CSpinner
              style="width:4rem;height:4rem;"
              color="primary"
            />
            <p class="text-secondary my-2">Cargando datos ...</p>
          </div>
          <CRow v-else>
            <CCol col="12" xl="12">
              <h6 clasS="text-center">Mediciones realizadas</h6>
              <p class="text-center text-muted">Se muestran todas las mediciones realizadas dentro del rango de tiempo seleccionado para el test holter.</p>
              <p class="text-center mb-0"><b>Entre</b></p>
              <p class="text-center mb-0"><b>{{holterData.start | filterDate}} - {{holterData.end | filterDate}}</b></p>
            </CCol>
            <CCol col="6" xl="6">
              <div class="my-3" style="max-height: 60vh;overflow-y: scroll;">
                <CDataTable
                  size="sm"
                  class="px-3 text-center"
                  style="font-size: 12px"
                  v-show="!dataLoading"
                  hover
                  striped
                  :items="holterMediciones"
                  :fields="fields"
                  :items-per-page="pagination.perPage"
                  index-column
                >
                  <template #created_at="data">
                    <td>{{data.item.created_at | filterDate}}</td>
                  </template>
                </CDataTable>
              </div>
            </CCol>
            <CCol col="6" xl="6">
              <CChartLine
                class="my-2"
                style="height:300px"
                :datasets="[
                  {
                    data: holterMedicionesGraph.sistolica,
                    borderColor: '#00BCD4',
                    pointRadius: 5,
                    fill: false,
                    label: 'Sistólica'
                  },
                  {
                    data: holterMedicionesGraph.diastolica,
                    borderColor: '#2196F3',
                    pointRadius: 5,
                    fill: false,
                    label: 'Diastólica'
                  },
                  {
                    data: holterMedicionesGraph.ritmo,
                    borderColor: '#FF9800',
                    pointRadius: 5,
                    fill: false,
                    label: 'Ritmo'
                  },
                  {
                    data: holterMedicionesGraph.oxigeno,
                    borderColor: '#3F51B5',
                    pointRadius: 5,
                    fill: false,
                    label: 'Oxígeno'
                  }
                ]"
                :labels="holterMedicionesGraph.labels"
                :options="{
                  maintainAspectRatio: false,
                  scales: {
                    xAxes: [{
                      ticks: {
                        beginAtZero:true,
                        callback: function(value, index, values) {
                          return filterDateGraph(value, 'DD-MM HH:mm');
                        }
                      }
                    }]
                  },
                  tooltips: {
                    mode: 'index',
                    callbacks: {
                      title: function(tooltipItems, data) {
                        return filterDateGraph(tooltipItems.xLabel);
                      }
                    }
                  }
                }"
              />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow>
        <CCol col="12" xl="12">
        </CCol>
      </CRow>
      <CRow>
        <CCol col="12" xl="12">
          <CButton @click="startCloseModal" color="primary" class="w-100">Cerrar</CButton>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="startCloseModal" color="primary" size="lg">Salir</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import {getHolterData} from '../../api/holter'
import moment from 'moment'

export default {
  name: 'ModalButton',
  props: ['showModal', 'holterID', 'closeModal'],
  filters: {
    filterDate: function(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    }
  },
  data () {
    return {
      fields: [
        { key: 'created_at', label: 'Fecha Medición' },
        { key: 'sistolica', label: 'Presión Sistólica' },
        { key: 'diastolica', label: 'Presión Diastólica' },
        { key: 'ritmo', label: 'Ritmo Cardíaco' },
        { key: 'oxigeno', label: 'Saturación Oxigeno' },
      ],
      dataLoading: true,
      holterData: null,
      holterMediciones: null,
      holterMedicionesGraph: null,
      pagination: {
        page: 1,
        perPage: 10,
        total: null
      }
    }
  },
  watch: {
    holterID: function (data) {
      if(data){
        this.getHolterData(data)
      }
    }
  },
  mounted(){
  },
  methods: {
    filterDateGraph(date, format = "DD-MM-YYYY HH:mm"){
      return moment(date).format(format)
    },
    getHolterData(holterID){
      const self = this
      getHolterData({holterID})
        .then(function(resp){
          console.log(resp)
          self.holterData = resp.holter
          self.holterMediciones = resp.mediciones
          self.holterMedicionesGraph = resp.mediciones_graph
          self.dataLoading = false
        })
        .catch(function(error){
          console.log(error)
        })
    },
    startCloseModal(){
      console.log("CERRAr")
      this.holterData = null
      this.holterMediciones = null
      this.holterMedicionesGraph = null
      this.dataLoading = true
      this.closeModal()
    }
  }
}
</script>

<style type="text/css" media="screen">
  .select-cierre-tipo .vs__search::placeholder,
  .select-cierre-tipo .vs__dropdown-toggle,
  .select-cierre-tipo .vs__dropdown-menu,
  .select-cierre-tipo .vs__open-indicator{
    color: #d0282e;
    border-color: #d0282e;
  }
</style>