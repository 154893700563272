import store from './store.js'

export default function checkPermissions(permissions = null){
	let permisos = []
	let result = true
	store.state.userData.type === 9 ? permisos.push('isSuperAdmin') : null
	if(store.state.userData.permisos){
		Object.entries(store.state.userData.permisos.permissions).forEach(function([key, value]){
			value ? permisos.push(key) : null
		});
	}
	permissions.map(function(permission){
		if( !permisos.includes(permission) ){
			result = false
		}
	})
	return result
}