<template>
  <CRow class="ficha-paciente-container">
    <CCol col="12" xl="12">
      <CToaster :autohide="3000">
        <template v-for="(toast, index) in toasts">
          <CToast
            :key="'toast' + index"
            :show="true"
            :header="toast.title"
          >
            {{toast.message}}
          </CToast>
        </template>
      </CToaster>
      <transition name="slide">
        <CCard>
          <CCardHeader>
            <span v-if="pacienteData">Ficha clínica usuario: <b>{{pacienteData.name}}</b></span>
          </CCardHeader>
          <CCardBody>
            <div 
              v-if="dataLoading"
              class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
              <CSpinner
                style="width:4rem;height:4rem;"
                color="primary"
              />
              <p class="text-secondary my-2">Cargando datos ...</p>
            </div>
            <CRow v-else>
              <CCol col="12" xl="12">
                <CRow>
                  <CCol col="6" xl="6">
                    <div class="ficha-paciente-card">
                      <h4>Datos Usuario</h4>
                      <div class="user-data" v-if="pacienteData && pacienteData.info">
                        <img
                          v-if="pacienteData.info.avatar"
                          style="width: 100px;height: 100px;border-radius: 100px;"
                          :src="pacienteData.info.avatar"
                          class="c-mb-2"
                        />
                        <div class="icon-user" v-else><font-awesome-icon icon="user"/></div>
                        <div class="datos-usuario">
                          <p class="mb-0">Nombre: {{pacienteData.name}}</p>
                          <p class="mb-0">Email: {{pacienteData.email}}</p>
                          <p class="mb-0">Teléfono: {{pacienteData.info.telefono}}</p>
                          <p class="mb-0">RUT: {{pacienteData.info.rut}}</p>
                          <p class="mb-0">Dirección: {{pacienteData.info.telefono}}</p>
                          <p class="mb-0">Fecha nacimiento: {{pacienteData.info.nacimiento}}</p>
                          <p class="mb-0">Género: {{pacienteData.info.genero === 1 ? 'Masculino':'Femenino'}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="ficha-paciente-card">
                      <h4>Datos Paciente</h4>
                      <div class="user-data" v-if="pacienteData && pacienteData.ficha">
                        <div class="icon-user"><font-awesome-icon icon="notes-medical" /></div>
                        <div class="datos-usuario">
                          <p class="mb-0">Estatura: {{pacienteData.ficha.estatura}} centímetros</p>
                          <p class="mb-0">Peso: {{pacienteData.ficha.peso}} kilos</p>
                        </div>
                        <div class="datos-enfermedades text-center justify-content-center d-flex flex-column">
                          <h6 class="mb-0">Enfermedades Preexistentes</h6>
                          <p class="mb-0" v-for="enfermedad in pacienteData.ficha.enfermedades">{{enfermedad}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="ficha-paciente-card">
                      <h4>Mediciones Smartbands</h4>
                      <CDataTable
                        class="p-3"
                        style="font-size: 12px"
                        v-show="!dataLoading"
                        hover
                        striped
                        :items="mediciones"
                        :fields="fields"
                        :items-per-page="pagination.perPage"
                        index-column
                      >
                        <template #created_at="data">
                          <td>{{data.item.created_at | filterDate}}</td>
                        </template>
                      </CDataTable>
                      <!-- <Pagination :data="pagination" :callback="paginate"/> -->
                    </div>
                  </CCol>
                  <CCol col="6" xl="6">
                    <div class="ficha-paciente-card">
                      <h4>Acciones</h4>
                      <div class="d-flex justify-content-center flex-column p-3">
                        <h5 class="text-center">Configurar Test Holter</h5>
                        <p class="text-center">Al configurar un test holter se activarán las mediciones automáticas de presión, ritmo cardíaco y saturación de oxígeno en la Smartband del usuario. Esta acción no sobreescribe la configuración automática generada por el usuario desde su aplicación. El usuario puede cancelar este Test si lo desea desde la configuración de las mediciones automáticas de la aplicación.</p>
                        <p class="text-center">Es recomendable contactar al usuario antes de configurar este test, ya que el usuario debe estar usando su Smartband y debe tener su teléfono cerca para poder enviar las mediciones al servidor en todo momento.</p>
                        <p class="text-center">De todas formas se enviará una notificación al teléfono del usuario con la información que configures en el holter. Además enviaremos una notificación 10 minutos antes de comenzar el Test Holter para que el paciente pueda prepararse.</p>
                        <CRow>
                          <CCol col="4" xl="4">
                            <p class="mb-0">Intervalo de medición</p>
                            <v-select
                              class="my-2"
                              placeholder="Cada cuanto tiempo deseas tomar las mediciones"
                              :options="[
                                {id: 1, name: 'cada 30 minutos'},
                                {id: 2, name: 'cada 1 hora'},
                                {id: 3, name: 'cada 6 horas'},
                                {id: 4, name: 'cada 12 horas'},
                                {id: 5, name: '1 vez al día'},
                              ]"
                              :reduce="data => data.id"
                              label="name"
                              :clearable="false"
                              v-model="holterConfig.timerange"
                              ></v-select>
                          </CCol>
                          <CCol col="4" xl="4">
                            <p class="mb-0">Fecha y hora de inicio</p>
                            <datetime 
                              width="100%"
                              class="my-2 datepicker-iduam"
                              type="datetime"
                              :min-datetime="minDate"
                              :minute-step="30"
                              v-model="holterConfig.start"></datetime>
                          </CCol>
                          <CCol col="4" xl="4">
                            <p class="mb-0">Fecha y hora de término</p>
                            <datetime 
                              width="100%"
                              class="my-2 datepicker-iduam"
                              type="datetime"
                              :min-datetime="holterConfig.start"
                              :minute-step="30"
                              v-model="holterConfig.end"></datetime>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol col="12" xl="12">
                            <CButton
                              color="primary"
                              class="py-2 my-1 w-100"
                              @click="createHolter"
                              size="sm"
                            >
                              Programar Test Holter
                            </CButton>
<!--                             <CButton
                              color="primary"
                              class="py-2 my-1 w-100"
                              @click="startHolter"
                              size="sm"
                            >
                              Start Test Holter
                            </CButton>
                            <CButton
                              color="primary"
                              class="py-2 my-1 w-100"
                              @click="stopHolter"
                              size="sm"
                            >
                              Stop Test Holter
                            </CButton> -->
                            <h5 class="my-2 py-2 text-center holters-programados-title">
                              Holters Programados
                              <CButton
                                color="primary"
                                size="sm"
                                class="m-1"
                                @click="() => {this.showHoltersProgramados = !this.showHoltersProgramados}"
                              >
                                <font-awesome-icon :icon="showHoltersProgramados ? 'chevron-up':'chevron-down'" />
                              </CButton>
                            </h5>
                            <CDataTable
                              class="p-3"
                              style="font-size: 12px"
                              v-show="!dataLoading && showHoltersProgramados"
                              hover
                              striped
                              :items="pacienteData.holters"
                              :fields="fieldsHolters"
                              :items-per-page="pagination.perPage"
                              index-column
                            >
                              <template #status="data">
                                <td>{{data.item | filterStatus}}</td>
                              </template>
                              <template #start="data">
                                <td>{{data.item.start | filterDate}}</td>
                              </template>
                              <template #end="data">
                                <td>{{data.item.end | filterDate}}</td>
                              </template>
                              <template #timerange="data">
                                <td>{{data.item.timerange | filterTimerange}}</td>
                              </template>
                              <template #actions="data">
                                <td class="py-0 d-flex justify-content-center align-item-center ">
                                  <CButton
                                    v-c-tooltip="{content: 'Visualizar', placement: 'left'}"
                                    color="info"
                                    size="sm"
                                    class="m-1"
                                    @click="showModalHolterData(data.item.id)"
                                    v-if="data.item.status === 2"
                                  >
                                    <font-awesome-icon icon="notes-medical" />
                                  </CButton>
                                  <CButton
                                    v-c-tooltip="{content: 'Eliminar Holter', placement: 'left'}"
                                    color="danger"
                                    size="sm"
                                    class="m-1"
                                    @click="destroyHolter(data.item.id)"
                                    v-if="!data.item.deleted_at && data.item.status !== 2"
                                  >
                                    <font-awesome-icon icon="trash-alt" />
                                  </CButton>
                                </td>
                              </template>
                            </CDataTable>
                          </CCol>
                        </CRow>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </transition>
      <modalHolterData :showModal="showHolterData" :holterID="showingHolterID" :closeModal="closeModalHolterData"/>
    </CCol>
  </CRow>
</template>

<script>
import Pagination from '../../helpers/Pagination'
import checkPermissions from '../../helpers/userPermissions'
import {getDetails} from '../../api/user'
import {createUserHolter,deleteHolter,startUserHolter,stopUserHolter} from '../../api/holter'
import vSelect from 'vue-select'
import moment from 'moment'
import modalHolterData from './modalHolterData'

export default {
  name: 'FichaClinica',
  components: {Pagination, 'v-select': vSelect, modalHolterData},
  props: ['paciente'],
  filters: {
    filterStatus: function(data){
      if(data.deleted_at){
        return 'Finalizado'
      }
      else if(data.status === 2){
        return 'En curso'
      }
      return 'Programado'
    },
    filterDate: function(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    },
    filterTimerange: function(index){
      const timerange = [
        'cada 30 minutos',
        'cada 1 hora',
        'cada 6 horas',
        'cada 12 horas',
        '1 vez al día',
      ]
      return timerange[index - 1]
    }
  },
  data: () => {
    return {
      dataLoading: true,
      pacienteID: null,
      showHolterData: false,
      showingHolterID: null,
      showHoltersProgramados: true,
      pacienteData: null,
      fields: [
        { key: 'created_at', label: 'Fecha Medición' },
        { key: 'sistolica', label: 'Presión Sistólica' },
        { key: 'diastolica', label: 'Presión Diastólica' },
        { key: 'ritmo', label: 'Ritmo Cardíaco' },
        { key: 'oxigeno', label: 'Saturación Oxigeno' },
      ],
      fieldsHolters: [
        { key: 'status', label: 'Estado' },
        { key: 'start', label: 'Inicio' },
        { key: 'end', label: 'Término' },
        { key: 'timerange', label: 'Intervalo' },
        { key: 'actions', label: 'Acción' }
      ],
      toasts: [],
      minDate: moment().add(30 - (moment().minute() % 30), 'm').set({second:0}).toISOString(),
      holterConfig: {
        timerange: 1,
        start: moment().add(30 - (moment().minute() % 30), 'm').set({second:0}).toISOString(),
        end: moment().add(30 - (moment().minute() % 30), 'm').add(1, 'd').set({second:0}).toISOString(),
      },
      pagination: {
        page: 1,
        perPage: 10,
        total: null
      }
    }
  },
  mounted(){
    this.pacienteID = this.$route.params.id
    this.getUserData(this.$route.params.id)
  },
  methods: {
    checkPermissions,
    showModalHolterData(holterID){
      console.log(holterID)
      this.showHolterData = true
      this.showingHolterID = holterID
    },
    closeModalHolterData(){
      this.showHolterData = false
      this.showingHolterID = null
    },
    async getUserData(userID){      
      const userData = await getDetails(userID)
      console.log(userData)
      this.dataLoading = false
      if(userData.code === 200){
        this.pacienteData = userData.user
        this.mediciones = userData.user.mediciones
      }
    },
    paginate(page, perPage){
      this.pagination = {
        page: page,
        perPage: perPage,
        total: this.pagination.total
      }
      this.getMediciones()
    },
    getMediciones(){
      const data = {
        limit: this.pagination.perPage,
        page: this.pagination.page
      }
      const self = this
      this.loading = true
    },
    createHolter(){
      const data = {
        user_id: this.pacienteID,
        timerange: this.holterConfig.timerange,
        start: moment( this.holterConfig.start ).format("YYYY/MM/DD HH:mm"),
        end: moment( this.holterConfig.end ).format("YYYY/MM/DD HH:mm"),
      }
      const self = this
      createUserHolter(data)
        .then(function(resp){
          self.toasts.push({
            title: 'Test Holter Creado',
            message: 'Se ha creado el test holter, hemos enviado una notificación al usuario.'
          })
          self.getUserData(self.$route.params.id)
        })
        .catch(function(error){
          console.log(error)
          self.toasts.push({
            title: 'Error',
            message: 'Hubo un error al crear el test holter, por favor recarga la página e intenta nuevamente.'
          })
        })
    },
    destroyHolter(holterID){
      const self = this
      deleteHolter({id: holterID})
        .then(function(resp){
          console.log("R: ", resp)
          self.getUserData(self.$route.params.id)
        })
        .catch(function(error){
          console.log(error)
        })
    },
    startHolter(){
      startUserHolter()
        .then(function(resp){
          console.log("R: ", resp)
        })
        .catch(function(error){
          console.log(error)
        })
    },
    stopHolter(){
      stopUserHolter()
        .then(function(resp){
          console.log("R: ", resp)
        })
        .catch(function(error){
          console.log(error)
        })
    }
  }
}
</script>